import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

const layoutQuery = graphql`
  query {
    logoIcon: file(
      relativePath: { eq: "logo-rect.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 96) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const Layout = (props) => {
  const {
    data, title, children, minimalMode, theme,
  } = props;
  const [toggleNav, setToggleNav] = React.useState(false);

  if (minimalMode) {
    return (
      <div className={`site-wrapper ${toggleNav ? 'site-head-open' : ''} ${theme}`}>
        <header className="site-head">
          <div className="site-head-container">
            {/* <div className="site-head-center">
              <Link className="site-head-logo" to="/">
                <br />
                <Img
                  fluid={data.logoIcon.childImageSharp.fluid}
                  style={{ filter: 'invert(1)' }}
                />
                <span className="logo-label" style={{ filter: 'invert(1)' }}>{title}</span>
              </Link>
            </div> */}
          </div>
        </header>
        <main id="site-main" className="site-main">
          <div id="swup" className="transition-fade">
            {children}
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className={`site-wrapper ${toggleNav ? 'site-head-open' : ''} ${theme}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href="#"
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-elements" role="menuitem">
                <Link to="/templates">Templates</Link>
              </li>
              <li className="nav-uploader" role="menuitem">
                <Link to="/uploader">Uploader</Link>
              </li>
              <li className="nav-books" role="menuitem">
                <Link to="/books">Books</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to="/about">Contact</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to="/">
              <Img
                fluid={data.logoIcon.childImageSharp.fluid}
              />
              <span className="logo-label">{title}</span>
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://twitter.com/arteaterx"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                href="https://www.instagram.com/arteaterx/"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>

      {/* <footer className="site-foot">
          &copy;
        {' '}
        {new Date().getFullYear()}
        {' '}
        <Link to="/about">
          {title}
        </Link>
      </footer> */}
    </div>
  );
};

export default props => (
  <StaticQuery
    query={layoutQuery}
    render={data => (
      <Layout data={data} {...props} />
    )}
  />
);
